import React from 'react';
import '../styles/Home.css';
import profilePhoto from '../assets/me2.jpeg'; // Adjust the path to your photo

const Home = () => {
  return (
    <div className="home-container">
      <div className="hero">
        <img src={profilePhoto} alt="Profile" className="hero-photo" />
        <div className="hero-content">
          <h1>Welcome to Speroverse</h1>
          <p>Your gateway to a world of sports, computer science, fun, and learning.</p>
          <a href="#/about" className="cta-button">Learn More</a>
        </div>
      </div>
      <div className="sections">
        <div className="section">
          <h2>About Me</h2>
          <p>Learn about my journey and experiences.</p>
          <a href="#/about" className="section-link">Read More</a>
        </div>
        <div className="section">
          <h2>Projects</h2>
          <p>Explore the projects I've worked on.</p>
          <a href="#/projects" className="section-link">View Projects</a>
        </div>
        <div className="section">
          <h2>Contact</h2>
          <p>Get in touch with me for collaborations and inquiries.</p>
          <a href="#/contact" className="section-link">Contact Me</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
