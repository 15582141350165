import {Component, React } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css';
import logoImage from '../assets/speroverse.png'; // Adjust the path to your logo image



class NavBar extends Component {
    state = {clicked: false};
    handleClick= ()=>{
      this.setState({clicked:
          !this.state.clicked})
      }
    render() {
  return (
    <nav className="navbar">
      <NavLink to="/" className="logo">
        <img src={logoImage} alt="Speroverse Logo" className="logo-image" />
      </NavLink>
      <ul id="navbars" className=
            {this.state.clicked ? "active-nav-links" : "nav-links"}>
        <li><NavLink to="/" className="nav-link" activeClassName="active">Home</NavLink></li>
        <li><NavLink to="/about" className="nav-link" activeClassName="active">About</NavLink></li>
        <li><NavLink to="/projects" className="nav-link" activeClassName="active">Projects</NavLink></li>
        <li><NavLink to="/contact" className="nav-link" activeClassName="active">Contact</NavLink></li>
      </ul>
      
      <div id="mobile" onClick={this.handleClick}>
        <i
            id="bar"
            className=
            {this.state.clicked ? "fas fa-times" : " fas fa-bars"}
        ></i>
        </div>
    </nav>
  );
}
};

export default NavBar;
