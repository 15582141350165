// src/components/About.jsx

import React from 'react';
import '../styles/About.css';
import topRightImage from '../assets/wisco.jpeg'; // Import the top right image
import bottomLeftImage from '../assets/tower.jpeg'; // Import the bottom left image

const About = () => {
  return (
    <div className="about-container">
      <div className="images-container-right">
        <img src={topRightImage} className="top-right-image" alt="Top Right" />
        </div>
        <div className="text-container">
          <h1>About Me</h1>
          <p>Welcome to Speroverse! My name is Michael Spero, and I am currently a Devops Engineer on the Data Mesh Team for Northern Trust. Some of tools/technolgies I work with on a daily basis are Terraform, Python, Docker, Kubernetes and Astronomer.  </p> 
          <p> I grew up in Northbrook, Illinois and graduated from the University of Wisconsin-Madison in the Spring of 2023 with a computer science degree. Prior to working at Northern Trust, I worked a both a developer and NBA Writer for the fantasy sports company Rotowire </p>
          <p> I am a passionate sports fan and am planning on using this platform to deliver high-quality reports combining my skills with technology and love for sports.  </p>
          <p>This is just the beginning.</p>
        </div>
        <div className="images-container-right">
        <img src={bottomLeftImage} className="bottom-left-image" alt="Bottom Left" />
        </div>
      </div>
  );
};

export default About;