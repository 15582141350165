import React from 'react';
import '../styles/About.css';

const Contact = () => {
  return (
    <div className="about-container">
      <div className="text-container">
        <h1>Contact Me</h1>
        <p>Feel free to reach out to me for any inquiries or collaborations.</p>
        <p>Email: Michaelrspero@gmail.com</p>
      </div>
    </div>
  );
};

export default Contact;