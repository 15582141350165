import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import About from './components/About';
import Home from './components/Home';
import Contact from './components/Contact';
import NavBar from './components/NavBar';
import NotFoundPage from './components/NotFoundPage';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, RouterProvider, HashRouter, createHashRouter, createBrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createHashRouter([{
  path: '/',
  element:       <div>
  <NavBar />
  <Home />
  </div>,
  errorElement: <NotFoundPage />
},
{
  path: '/About',
  element: <div>
  <NavBar />
  <About />
  </div>,
},
  {
    path: '/Contact',
    element: <div>
    <NavBar />
    <Contact />
    </div>,
}])



root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
